// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

// ** UseJWT import to get config
import useJwt from "@src/auth/jwt/useJwt";

const config = useJwt.jwtConfig;

const initialUser = () => {
  const item = window.localStorage.getItem("userData");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser(),
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload;
      state[config.storageTokenKeyName] =
        action.payload[config.storageTokenKeyName];
      state[config.storageRefreshTokenKeyName] =
        action.payload[config.storageRefreshTokenKeyName];
      localStorage.setItem("userData", JSON.stringify(action.payload));
      localStorage.setItem(
        config.storageTokenKeyName,
        JSON.stringify(action.payload.accessToken)
      );
      localStorage.setItem(
        config.storageRefreshTokenKeyName,
        JSON.stringify(action.payload.refreshToken)
      );
    },
    handleLogout: (state) => {
      state.userData = {};
      state[config.storageTokenKeyName] = null;
      state[config.storageRefreshTokenKeyName] = null;
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem("userData");
      localStorage.removeItem(config.storageTokenKeyName);
      localStorage.removeItem(config.storageRefreshTokenKeyName);
    },
    handleRegister: (state, action) => {
      state.userData = action.payload.userData;
      state[config.storageTokenKeyName] =
        action.payload.userData[config.storageTokenKeyName];
      //state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem("userData", JSON.stringify(action.payload.userData));
      localStorage.setItem(
        config.storageTokenKeyName,
        JSON.stringify(action.payload.userData.accessToken)
      );
      //localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
    },
    updateUser: (state, action) => {
      console.log("updateUser", action.payload.userData);
      state.userData = {
        ...action.payload.userData,
        role: action.payload.userData.firebaseRole,
      };
      localStorage.setItem(
        "userData",
        JSON.stringify({
          ...action.payload.userData,
          role: action.payload.userData.firebaseRole,
        })
      );
    },
    setVoiceId: (state, action) => {
      console.log("setVoiceId", action.payload.userData);
      state.userData = {
        ...action.payload.userData,
        voiceId: action.payload.userData.voiceId,
      };
      localStorage.setItem(
        "userData",
        JSON.stringify({
          ...action.payload.userData,
          voiceId: action.payload.userData.voiceId,
        })
      );
    },
  },
});

export const {
  handleLogin,
  handleLogout,
  handleRegister,
  updateUser,
  setVoiceId,
} = authSlice.actions;

export default authSlice.reducer;
