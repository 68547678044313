import React, { createContext, useState, useContext } from "react";
import Joyride, { STATUS, EVENTS, ACTIONS } from "react-joyride";
/* import "./TourProvider.css"; */

const TourContext = createContext();

export const TourProvider = ({ children }) => {
  const [tourSteps, setTourSteps] = useState([]);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [spotlightClicks, setSpotlightClicks] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [disableScrolling, setDisableScrolling] = useState(true);

  const startTour = (steps, config = {}) => {
    if (config.spotlightClicks !== undefined) {
      setSpotlightClicks(config.spotlightClicks);
    }
    if (config.disableScrolling !== undefined) {
      setDisableScrolling(config.disableScrolling);
    }
    setTourSteps(steps);
    setIsTourOpen(true);
    setStepIndex(0);
  };

  const stopTour = () => {
    setIsTourOpen(false);
    setTourSteps([]);
  };

  const scrollToElement = (selector) => {
    const element = document.querySelector(selector);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const customStyles = {
    options: {
      zIndex: 10000,
    },
    buttonNext: {
      backgroundColor: "#023E73", // Custom color for the Next button
      color: "white",
      radius: "15px",
      fontSize: "1rem",
    },
    buttonBack: {
      color: "#023E73", // Custom color for the Back button
      fontSize: "0.9rem",
    },
    buttonSkip: {
      backgroundColor: "#F2D888", // Custom color for the Skip button
      color: "black",
      radius: "15px",
      fontSize: "0.9rem",
    },
    tooltip: {
      border: "3px solid #023E73", // Custom border color globally
      maxWidth: "350px",
      fontSize: "1rem",
      minHeight: "100px",
    },
    /* buttonClose: {
      backgroundColor: "#023E73", // Custom color for the Close button
      color: "white",
    }, */
  };

  const handleJoyrideCallback = (data) => {
    const { status, action, index, type } = data;

    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (type === EVENTS.TARGET_NOT_FOUND) {
      // Stop the tour if the target is not found
      setIsTourOpen(false);
    }

    if (
      [EVENTS.STEP_AFTER, EVENTS.STEP_BEFORE, EVENTS.TOUR_END].includes(type)
    ) {
      // You can perform other checks based on action, index, type, and status
      if (action === "close" || status === "finished" || status === "skipped") {
        setIsTourOpen(false); // Stop the tour
      }
    }

    const step = tourSteps[index];
    if (step && step.target && !disableScrolling) {
      setTimeout(() => {
        scrollToElement(step.target);
      }, 300); // Add a delay before scrolling to the target element
    }

    if (finishedStatuses.includes(status)) {
      stopTour();
    } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT) {
      if (tourSteps[index].pauseTour) {
        setIsTourOpen(false);
      } else {
        if (index + 1 < tourSteps.length && tourSteps[index + 1].scrollTo) {
          scrollToElement(tourSteps[index + 1].scrollTo);
        }
        setStepIndex(index + 1);
      }
    } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV) {
      if (tourSteps[index - 1].scrollTo) {
        scrollToElement(tourSteps[index - 1].scrollTo);
      }
      setStepIndex(index - 1);
    }
  };

  return (
    <TourContext.Provider
      value={{
        tourSteps,
        isTourOpen,
        setIsTourOpen,
        startTour,
        stopTour,
        stepIndex,
        setStepIndex,
      }}
    >
      {children}
      <Joyride
        steps={tourSteps}
        stepIndex={stepIndex}
        continuous
        showProgress
        showSkipButton
        run={isTourOpen}
        callback={handleJoyrideCallback}
        spotlightClicks={spotlightClicks}
        styles={customStyles}
        disableScrolling={disableScrolling}
      />
    </TourContext.Provider>
  );
};

export const useTour = () => useContext(TourContext);
