// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('appInvoice/getData', async params => {
  const response = await axios.get('/apps/invoice/invoices', params)
  return {
    params,
    data: response.data.invoices,
    allData: response.data.allData,
    totalPages: response.data.total
  }
})

export const deleteInvoice = createAsyncThunk('appInvoice/deleteInvoice', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/invoice/delete', { id })
  await dispatch(getData(getState().invoice.params))
  return id
})

export const appInvoiceSlice = createSlice({
  name: 'appInvoice',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    SaaSinvoice: []
  },
  reducers: { 
    setSaaSinvoice: (state, action) => {
    console.log("setSaaSinvoice", action.payload)
    state.SaaSinvoice = action.payload
   }
  },
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.params = action.payload.params
    })
  }
})

export const { setSaaSinvoice } = appInvoiceSlice.actions

export default appInvoiceSlice.reducer
